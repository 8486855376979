"use client";

import { zodResolver } from "@hookform/resolvers/zod";
import { CheckIcon, ChevronDown, X } from "lucide-react";
import React, { useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import NoContent from "#/components/no-content";
import { cn } from "#/lib/utils";
import { Badge } from "#/ui/badge";
import { Command, CommandItem } from "#/ui/command";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "#/ui/form";
import { Input } from "#/ui/input";
import { Popover, PopoverContent, PopoverTrigger } from "#/ui/popover";
import { RemoveSpecialChars } from "#/utils/StringUtils";
import clsx from "clsx";
import * as JsSearch from "js-search";
import {
  AutoSizerProps,
  ListProps,
  AutoSizer as _AutoSizer,
  List as _List,
} from "react-virtualized";
import { twMerge } from "tailwind-merge";

const List = _List as unknown as React.FC<ListProps>;
const AutoSizer = _AutoSizer as unknown as React.FC<AutoSizerProps>;

const schema = z.object({
  array_field: z.array(z.string()).optional(),
});

export default function MultiSelect({
  options,
  defaultValues,
  disabled,
  className,
  classNameInput,
  onChange,
  placeholder,
  title,
  onCloseAutoFocus,
  classNameTitle,
  disableSelect,
  enableSelectAll = false,
}: {
  defaultValues: string[];
  options: { value: string; label: string }[];
  title?: string;
  disabled?: boolean;
  className?: string;
  classNameTitle?: string;
  disableSelect?: boolean;
  classNameInput?: string;
  onCloseAutoFocus?: () => void;
  placeholder?: string;
  onChange?: (data: { value: string; label: string }[]) => void;
  enableSelectAll?: boolean;
}) {
  const [selectedValues, setSelectedValues] = React.useState(
    () => new Set<string>(defaultValues),
  );

  const [search, setSearch] = React.useState("");
  const [results, setResults] = React.useState([]);

  const client = React.useMemo(() => {
    const s = new JsSearch.Search("label");
    s.indexStrategy = new JsSearch.AllSubstringsIndexStrategy();
    s.addIndex("label");
    s.addDocuments(options);
    return s;
  }, [options]);

  React.useEffect(() => {
    const cleaned = RemoveSpecialChars(search);
    const searchRes = client.search(cleaned);
    const sortedSearchRes = searchRes.sort(
      (a, b) => a.label.indexOf(cleaned) - b.label.indexOf(cleaned),
    );
    setResults(sortedSearchRes);
  }, [search]);

  const onCapture = React.useCallback((e) => setSearch(e.target.value), []);

  const beforeChange = useCallback(
    (next) => {
      onChange(
        [...next].map((v) => ({
          value: v,
          label: options?.find((o) => o?.value === v)?.label,
        })),
      );
    },
    [options],
  );

  const onOpenChanged = useCallback((value) => value && setSearch(""), []);
  const onSelectAll = useCallback(() => {
    if (disabled) return;
    const newSet = new Set(options.map((option) => option.value));
    setSelectedValues(newSet);
    beforeChange(newSet);
  }, [options, disabled]);

  const onClearAll = useCallback(() => {
    setSelectedValues(new Set());
    beforeChange([]);
  }, []);

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
  });

  return (
    <Form {...form}>
      <FormField
        control={form.control}
        name="array_field"
        render={() => (
          <FormItem>
            <Popover modal onOpenChange={onOpenChanged}>
              <PopoverTrigger asChild>
                <FormControl>
                  <div
                    className={twMerge(
                      "relative flex items-center justify-end rounded-xl border data-[state=open]:border-ring",
                      className,
                    )}
                  >
                    <FormLabel
                      className={cn(
                        "text-400 truncate text-muted text-sm absolute font-circular-std font-500 left-[1rem] top-[-0.75rem] bg-card z-10",
                        classNameTitle,
                      )}
                    >
                      {title}
                    </FormLabel>
                    <div
                      className={cn(
                        "relative h-10 overflow-y-auto flex-row mr-auto pt-2 mt-1 flex flex-grow flex-wrap px-3 py-1",
                        classNameInput,
                      )}
                    >
                      {selectedValues?.size > 0 ? (
                        options &&
                        options
                          .filter((option) => selectedValues.has(option.value))
                          .map((option) => (
                            <Badge
                              key={option.value}
                              className="m-[2px] gap-1 pr-0.5"
                            >
                              <span className="">{option.label}</span>
                              <span
                                onClick={(e) => {
                                  if (disabled || disableSelect) return;
                                  e.preventDefault();
                                  setSelectedValues((prev) => {
                                    const next = new Set(prev);
                                    next.delete(option.value);
                                    beforeChange(next);
                                    return next;
                                  });
                                }}
                                className="flex items-center rounded-sm px-[1px] hover:bg-accent hover:text-red-500"
                              >
                                {!disabled && !disableSelect && <X size={14} />}
                              </span>
                            </Badge>
                          ))
                      ) : (
                        <span className="mr-auto text-muted text-base">
                          {placeholder || "Select..."}
                        </span>
                      )}
                    </div>
                    <div className="flex flex-shrink-0 items-center self-stretch text-muted-foreground/60">
                      {selectedValues?.size > 0 && (
                        <div
                          onClick={(e) => {
                            if (disabled || disableSelect) return;
                            e.preventDefault();
                            setSelectedValues(new Set());
                            beforeChange([]);
                          }}
                          className="flex items-center self-stretch p-2 hover:text-red-500"
                        >
                          {!disabled && !disableSelect && <X size={16} />}
                        </div>
                      )}
                      <div className="flex items-center pr-2 self-stretch text-muted">
                        <ChevronDown size={16} />
                      </div>
                    </div>
                  </div>
                </FormControl>
              </PopoverTrigger>
              <PopoverContent
                onCloseAutoFocus={onCloseAutoFocus}
                className="w-[var(--radix-popover-trigger-width)] p-0"
                align="start"
                hidden={disabled}
              >
                <Command>
                  <div className="p-2">
                    <Input
                      onChange={onCapture}
                      value={search}
                      className="bg-card"
                      placeholder={placeholder || "Type here..."}
                    />
                  </div>
                  {enableSelectAll && (
                    <div className="flex justify-between px-2 py-1">
                      <button
                        className="text-sm text-primary hover:underline"
                        onClick={onSelectAll}
                        disabled={disabled || disableSelect}
                      >
                        Select All
                      </button>
                      <button
                        className="text-sm text-primary hover:underline"
                        onClick={onClearAll}
                        disabled={disabled || disableSelect}
                      >
                        Clear All
                      </button>
                    </div>
                  )}
                  <div className="w-full h-fit">
                    <ListComponent
                      search={search}
                      data={!!search?.length ? results : options}
                      beforeChange={beforeChange}
                      form={form}
                      disabled={disabled || disableSelect}
                      selectedValues={selectedValues}
                    />
                  </div>
                </Command>
              </PopoverContent>
            </Popover>
            <FormMessage />
          </FormItem>
        )}
      />
    </Form>
  );
}

const ListComponent = ({
  search,
  beforeChange,
  form,
  disabled,
  data,
  selectedValues,
}) => {
  const renderItem = ({ index, style, key }) => {
    const option = { ...data[index] };
    const isSelected = selectedValues.has(option.value);
    return (
      <CommandItem
        key={option?._id || key}
        style={style}
        disabled={disabled}
        onSelect={() => {
          if (disabled) return;
          if (isSelected) {
            selectedValues.delete(option.value);
          } else {
            selectedValues.add(option.value);
          }
          const filterValues = Array.from(selectedValues);
          form.setValue("array_field", filterValues);
          beforeChange(selectedValues);
        }}
      >
        <div
          className={cn(
            "mr-2 flex h-4 w-4 items-center justify-center rounded-xl border border-primary",
            isSelected
              ? "bg-[#27C499] text-white"
              : "opacity-50 [&_svg]:invisible",
          )}
        >
          <CheckIcon className={cn("h-4 w-4")} />
        </div>
        <span>
          <span className="line-clamp-1">{option.label}</span>
          {option?.description && (
            <span className="line-clamp-1 mt-[-3px] text-xs">
              {option?.description}
            </span>
          )}
        </span>
      </CommandItem>
    );
  };

  const containsDescription = useMemo(() => {
    return data?.some((d) => !!d?.description);
  }, [data]);

  return (
    <>
      <>
        <div
          className={twMerge(
            "w-full",
            clsx({
              "h-[17rem]": search?.length > 100,
              "h-[10rem]": search?.length < 100,
            }),
          )}
        >
          {!!search?.length && !data?.length ? (
            <NoContent title={`No results found for "${search}"`} />
          ) : (
            <AutoSizer>
              {({ height, width }: any) => (
                <List
                  height={height}
                  rowCount={data.length}
                  rowHeight={containsDescription ? 40 : 30}
                  rowRenderer={renderItem}
                  width={width}
                />
              )}
            </AutoSizer>
          )}
        </div>
      </>
    </>
  );
};
