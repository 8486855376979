"use client";

import { CircleOff } from "lucide-react";
import { twMerge } from "tailwind-merge";

export default function NoContent({
  title,
  className,
  message,
  children,
  icon,
}: {
  message?: string;
  title: string;
  className?: string;
  children?: React.ReactNode;
  icon?: React.ReactNode;
}) {
  return (
    <div
      className={twMerge(
        "flex items-center flex-col justify-center h-[40%] self-center min-h-[10rem]",
        className,
      )}
    >
      {icon || <CircleOff className="text-primary h-7 w-7 my-2" />}
      <p className="text-foreground font-500 text-xl">{title}</p>
      <p className="text-muted text-center font-400 text-base">{message}</p>
      {children}
    </div>
  );
}
