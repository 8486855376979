import { atoms } from "#/lib/atoms/atoms";
import { useSetAtom } from "jotai";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import cookies from "../lib/cookies";

export const useLoggedIn = () => {
  // might need to look at this if we see issues https://github.com/reactivestack/cookies/pull/288#issuecomment-895672629
  const [currentCookies] = useCookies([cookies.options.access_token]);
  const [loggedIn, setLoggedIn] = useState(
    !!currentCookies[cookies.options.access_token],
  );

  const setAccessToken = useSetAtom(atoms.accessToken);
  const setMid = useSetAtom(atoms.mid);
  const setOrgId = useSetAtom(atoms.orgId);

  useEffect(() => {
    const access_token = currentCookies[cookies.options.access_token];
    setLoggedIn(!!access_token);
    setAccessToken(access_token);
    const merchant_id = currentCookies[cookies.options.merchant_id];
    if (merchant_id) setMid(merchant_id);
    const org_id = currentCookies[cookies.options.org_id];
    if (org_id) setOrgId(org_id);
  }, [currentCookies]);

  return loggedIn;
};
